export const sygnet = ['32 32', `
  <title>Projecta</title>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 74 83" enable-background="new 0 0 74 83" xml:space="preserve">  <image id="image0" width="74" height="83" x="0" y="0"
  href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAABTCAMAAAAP12EQAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAANlBMVEUAAAAAn5oAn5oAn5oA
n5oAn5oAn5oAn5oAn5oAn5oAn5oAn5oAn5oAn5oAn5oAn5oAn5r///9Ximl7AAAAEHRSTlMAQIAw
j+8g36+/nxDPcGBQL2w5jQAAAAFiS0dEEeK1PboAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElN
RQflAxoOHAOHucxXAAABrUlEQVRYw+2Y25KDIAyGAUXkWN7/adcippy1xZ3tzPLfKeErpCTBIEzK
QiByUYjYsqaDhO1FVVGwrLkbRZedNNFulGVui/gyqYGydiWEXwa1UW9qoP4UxWuRxcD0zORABeEb
63WyzkwG6n2UwKmmMkpmhlidnSteRtWT6kAN1EAN1H9AUU7vQSn8fFI3oJTcU73qR/mrKpq6UQpK
l+pFzYCae1EroNZeFD1IkvaioOy/5n9+rrR71PYGlGXEBHfCr4nBgfoFlA8NmqDQB6hs5uconc4U
+wv2PgqnruFJJshQujLgQz8czt/EE4LkHMlvRwSv9pv8UndIpZGAc8/o1g7dH25KIzwtAhbyefFk
+QJUYNEp3x9UmUJrhUmfCXOWQSW/iLTIJGtya479RT0pWRQsSybuWl+kbZAEMJ6Xy9iD24kIzTGK
JY143iYoJ9C5kvnB1mBvxHObLDBvqdTbMQ17U6PKcpeoypq3GCx/yE6VKAhKfKhFuXBmOBuRjaZI
zXzPDEwv0Yrmdi9NGRmZC2cOSUbNev+oJoLZc3FinPWDrMfPfnenaKAG6ktRP7SoHs6Jg+x1AAAA
JXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAzLTI2VDE0OjI4OjAwKzAwOjAwKUIPbQAAACV0RVh0ZGF0
ZTptb2RpZnkAMjAyMS0wMy0yNlQxNDoyODowMCswMDowMFgft9EAAAAASUVORK5CYII=" />
</svg>
`]
