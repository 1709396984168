import React, { useCallback, useEffect, useMemo } from "react";
import {
  getDBCONF,
  getUrlApi,
  logoutHelper,
  setMENUCONF,
  updateToken,
} from "../../../helpers/Helpers";
import Cookies from "universal-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { CContainer } from "@coreui/react";

const cookies = new Cookies();

const AutoLogin = ({}) => {
  const history = useHistory();

  const { search } = useLocation();
  const queryParamsValues = useMemo(
    () => Array.from(new URLSearchParams(search).values()),
    [search]
  );

  const redirectPath = useMemo(() => {
    if (queryParamsValues && queryParamsValues.length >= 1) {
      return queryParamsValues[0];
    }

    return undefined;
  }, [queryParamsValues]);

  const encodedRouteParameters = useMemo(() => {
    let parametriPerRoute = [];
    if (queryParamsValues && queryParamsValues.length > 1) {
      parametriPerRoute = queryParamsValues.slice(1);
    }

    const routeParameters = {
      PARAMETRI: parametriPerRoute
        .reduce((acc, cv) => `${acc}${cv},`, "")
        .slice(0, -1),
      DEFAULT: undefined,
      CONTEXT: { context: undefined },
    };
    return encodeURI(setMENUCONF(JSON.stringify(routeParameters)));
  }, [queryParamsValues]);

  const apiToken = useMemo(() => cookies.get("JEProjectJWTTK"), [cookies]);

  const callApi = useCallback(
    async (name, method, body, successCallback, errorCallback) => {
      let apiBody = {
        conf: getDBCONF(),
        ...body,
      };

      if (apiToken) {
        apiBody.token = apiToken;
      }

      await fetch(`${getUrlApi()}${name}`, {
        method,
        body: JSON.stringify(apiBody),
        headers: { "Content-Type": "application/json" },
      })
        .then((results) => results.json())
        .then((json) => {
          if (json && json.error) {
            if (json.UUIDLogout) {
              logoutHelper();
            }
            return null;
          } else {
            successCallback(json);
          }
        })
        .catch(errorCallback);
    },
    []
  );

  const login = useCallback(async () => {
    var username = process.env.REACT_APP_USERNAME_AUTO_LOGIN;
    var password = process.env.REACT_APP_PASSWORD_AUTO_LOGIN;
    var durataToken = process.env.REACT_APP_NDAYS_COOKIE;

    updateToken(
      "JEProjectJWTTK",
      durataToken,
      "JEProjectTMP",
      {
        username,
        password,
      },
      history,
      redirectPath,
      encodedRouteParameters
    );
  }, [apiToken, redirectPath, encodedRouteParameters]);

  useEffect(() => {
    login();
  }, [apiToken, redirectPath, encodedRouteParameters, login]);

  return <CContainer />;
};

export default AutoLogin;
