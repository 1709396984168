import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import "../customStyle.css";
import "../scss/style.scss";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import { LocalizeProvider } from "react-localize-redux";
// Pages
import {
  Login,
  Report,
  ReportsViewer,
  ReportsCripted,
  Register,
  SketchfabViewer,
  AutoLogin,
} from "../viewsStandard/Pages/index";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { clearInterval, setInterval } from "worker-timers";
import { updateToken } from "../helpers/Helpers";

import {
  DevelopmentContext,
  DevelopmentContextProvider,
} from "../components/contexts/DevelopmentContext";

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

const cookies = new Cookies();

// Containers
const TheLayout = React.lazy(() => import("../containers/TheLayout"));
const MonitorVarco = React.lazy(() =>
  import("../containers/hr/MonitorVarco/MonitorVarco")
);
const MonitorGestionePresenze = React.lazy(() =>
  import("../containers/hr/MonitorGestionePresenze/MonitorGestionePresenze")
);

const RegistrazioneAccessi = React.lazy(() =>
  import("../viewsStandard/HR/RegistrazioneAccessi/RegistrazioneAccessi")
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      cookies.get("JEProjectJWTTK") !== undefined ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalStatus: null,
    };
    this.checkRenewCookie = this.checkRenewCookie.bind(this);
  }

  checkRenewCookie() {
    if (process.env.REACT_APP_RENEW_COOKIE == "true") {
      let timestampLastGenerationToken = cookies.get("JEProjectTMP");
      if (timestampLastGenerationToken !== undefined) {
        let durataToken = process.env.REACT_APP_NDAYS_COOKIE;
        let pct = process.env.REACT_APP_PCT_MISSING_EXPIRE_RENEW_COOKIE;
        let msAfterRenewToken =
          ((durataToken * (100 - pct)) / 100) * 24 * 60 * 60 * 1000;

        let currentDate = new Date();
        let dateRequiredRenewToken = new Date(timestampLastGenerationToken);
        dateRequiredRenewToken.setHours(
          dateRequiredRenewToken.getHours(),
          dateRequiredRenewToken.getMinutes(),
          dateRequiredRenewToken.getSeconds(),
          dateRequiredRenewToken.getMilliseconds() + msAfterRenewToken
        );

        if (currentDate > dateRequiredRenewToken) {
          updateToken(
            "JEProjectJWTTK",
            durataToken,
            "JEProjectTMP",
            null,
            null,
            null,
            null
          );
        }
      }
    }
  }

  componentDidMount() {
    let self = this;

    // 1/5 del tempo anticipato per il rinnovo del cookie
    let msTimerElapsed =
      (((process.env.REACT_APP_NDAYS_COOKIE *
        process.env.REACT_APP_PCT_MISSING_EXPIRE_RENEW_COOKIE) /
        100) *
        24 *
        60 *
        60 *
        1000) /
      5;

    if (self.state.intervalStatus) {
      clearInterval(self.state.intervalStatus);
    }

    let intervalStatus = setInterval(self.checkRenewCookie, msTimerElapsed);

    self.setState({ intervalStatus });
  }

  componentWillUnmount() {
    let self = this;
    clearInterval(self.state.intervalStatus);
  }

  render() {
    return (
      <DevelopmentContextProvider>
        <LocalizeProvider>
          <React.Suspense fallback={loading}>
            <BrowserRouter>
              <Switch key='routingApp'>
                <Route
                  exact
                  path='/login'
                  name='Login Page'
                  component={Login}
                />
                <Route
                  exact
                  path='/register'
                  name='Registrazione'
                  component={Register}
                />
                <Route exact path='/reports' name='Report' component={Report} />
                <Route
                  exact
                  path='/ReportsCripted'
                  name='ReportsCripted'
                  component={ReportsCripted}
                />
                <Route
                  exact
                  path='/reportsViewer'
                  name='ReportsViewer'
                  component={ReportsViewer}
                />
                <Route
                  exact
                  path='/sketchfabViewer'
                  name='SketchfabViewer'
                  component={SketchfabViewer}
                />
                <Route
                  exact
                  path='/autologin'
                  name='Autologin Page'
                  component={AutoLogin}
                />
                <Route
                  key='MonitorVarco'
                  path='/MonitorVarco/:parameter'
                  name='Monitor Accessi'
                  component={MonitorVarco}
                />
                <Route
                  key='MonitorGestionePresenze'
                  path='/MonitorGestionePresenze/:parameter'
                  name='Monitor Gestione presenze'
                  component={MonitorGestionePresenze}
                />
                <Route
                  key='MonitorAccessiVarco'
                  path='/MonitorAccessiVarco/:parameter'
                  name='Monitor accessi varco'
                  component={RegistrazioneAccessi}
                />
                <PrivateRoute
                  key='privateRoot'
                  path='/'
                  name='AreaRiservata'
                  component={(props) => (
                    <TheLayout
                      key='layoutPrivate'
                      {...props}
                      hideDashboard={this.props.hideDashboard}
                    />
                  )}
                />
              </Switch>
            </BrowserRouter>
          </React.Suspense>
        </LocalizeProvider>
      </DevelopmentContextProvider>
    );
  }
}

export default App;
