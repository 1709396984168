import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './common/polyfill'
import React from 'react';
import ReactDOM from 'react-dom';

import App from './common/App';
import * as serviceWorker from './common/serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './common/store'


React.icons = icons


ReactDOM.render(
  <Provider store={store}>
    <App hideDashboard />
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();