import React, { useEffect, useRef } from "react";
import { withLocalize } from "react-localize-redux";
/**
 * @example import UCSketchfabViewer from "../components/basic/JESketchfabViewer";
 * @example <UCSketchfabViewer url={this.state.idModelloSketchfab} />
 * @category componenti
 * @property {string} url URL a cui puntare
 */

const UCSketchfabViewer = (props) => {
  const viewerIframeRef = useRef(null);
  const ViewerIframe = (
    <iframe
      ref={viewerIframeRef}
      id='sketchfab-viewer'
      title='sketchfab-viewer'
      style={{ height: "calc(95vh)", width: "100%", margin: "auto" }}
    />
  );

  useEffect(() => {
    let client = new window.Sketchfab(viewerIframeRef.current);
    client.init(props.url, {
      autostart: 1,
      success: () => {},
      error: () => {
        console.log("Viewer error");
      },
    });
  }, []);

  return ViewerIframe;
};

export default withLocalize(UCSketchfabViewer);
