import React, { useState, createContext, useEffect } from "react";
import Cookies from "universal-cookie/lib/Cookies";
import { b64DecodeUnicode } from "../../helpers/Helpers";

const cookies = new Cookies();
const DevelopmentContext = createContext();

const DevelopmentContextProvider = ({ children }) => {
  const [devContext, setValue] = useState({
    status: false,
    options: { tooltipBlock: false, showAllEntities: false },
    // entitiesArray: [],
  });

  // Funzione per aggiornare il valore del contesto
  const changeDevelopmentContextValue = (v) => {
    setValue(v);
  };

  useEffect(() => {
    if (process.env.REACT_APP_DEVELOPER_MODE === "true") {
      const cookie = cookies.get("JEDevelopmentMode");
      if (cookie !== undefined) {
        let devModeStatusCookie = b64DecodeUnicode(cookie) === "true" ? true : false;
        setValue({
          status: devModeStatusCookie,
          options: { tooltipBlock: false, showAllEntities: false },
          entitiesArray: [],
        });
      } else {
        setValue({
          status: false,
          options: { tooltipBlock: false, showAllEntities: false },
          entitiesArray: [],
        });
      }
    }
  }, []);

  return (
    <DevelopmentContext.Provider value={{ devContext, changeDevelopmentContextValue }}>
      {children}
    </DevelopmentContext.Provider>
  );
};

export { DevelopmentContext, DevelopmentContextProvider };
