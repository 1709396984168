export const logo = ['198 39', `<svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" width="198" height="39" viewBox="0 0 198 39">
<defs>
  <style>
    .cls-1 {
      fill: #8e8c8c;
    }

    .cls-2 {
      fill: #009796;
    }
  </style>
</defs>
<g>
  <path class="cls-1" d="M122.73,19.57v-.08c0-7.69,5.74-14.06,13.74-14.06a14.14,14.14,0,0,1,10.6,4.31L145,12a11.86,11.86,0,0,0-8.55-3.72c-6,0-10.52,4.89-10.52,11.14v.08c0,6.29,4.54,11.22,10.52,11.22,3.73,0,6.18-1.44,8.78-3.92l2,2c-2.84,2.87-5.94,4.78-10.87,4.78C128.51,33.55,122.73,27.37,122.73,19.57Z"/>
  <path class="cls-1" d="M160,8.73h-9.12V5.9h21.35V8.73h-9.12V33.08H160Z"/>
  <polygon class="cls-1" points="185.1 5.7 182.23 5.7 169.84 33.08 172.98 33.08 183.63 9.28 194.19 33.08 197.49 33.08 185.1 5.7"/>
  <g>
    <path class="cls-1" d="M51.57,19.59v-.07A13.78,13.78,0,0,1,65.48,5.46a13.64,13.64,0,0,1,13.82,14v.08a13.87,13.87,0,1,1-27.73.07Zm24.55,0v-.07c0-6.18-4.51-11.23-10.72-11.23s-10.64,5-10.64,11.15v.08c0,6.17,4.5,11.22,10.72,11.22S76.12,25.77,76.12,19.59Z"/>
    <path class="cls-1" d="M10.69,5.92H.51V33.11H3.58V8.76h6.87c4.39,0,7.3,2,7.3,5.9v.08c0,3.57-2.95,6-7.45,6H6.65v2.8h3.53c5.79,0,10.68-3,10.68-8.9v-.08C20.86,9.19,16.82,5.92,10.69,5.92Z"/>
    <path class="cls-1" d="M39.59,22c4.27-.78,7.38-3.42,7.38-7.92V14a7.51,7.51,0,0,0-2.1-5.36,10.72,10.72,0,0,0-7.72-2.72H25.46V33.11h3.07V8.76h8.38c4.39,0,7,2,7,5.36v.08c0,3.49-2.91,5.59-7,5.59H34.23l2.06,2.75h0l7.89,10.57h3.76Z"/>
  </g>
  <path class="cls-2" d="M111.67,33.47a8.35,8.35,0,0,1-6-2.25c-1.55-1.56-2.49-4-2.49-7.15V5.9h11.57V9h-8.46V24.11c0,4.31,2.25,6.52,5.32,6.52,2.83,0,4.53-1.35,6.2-3.86V.5H99.4a2.33,2.33,0,0,1-2.33,2.33A2.33,2.33,0,0,1,94.74.5H84V26.77c1.66,2.51,3.37,3.86,6.2,3.86,3.06,0,5.32-2.21,5.32-6.52V9H87V5.9H98.6V24.07c0,3.19-.93,5.59-2.48,7.15a8.38,8.38,0,0,1-6,2.25A9.11,9.11,0,0,1,84,31.31V38.5h33.85V31.31A9.13,9.13,0,0,1,111.67,33.47Zm0-11.65A2.33,2.33,0,1,1,114,19.49,2.33,2.33,0,0,1,111.63,21.82Z"/>
</g>
</svg>`]
