import React, { Component } from "react";
import UCSketchfabViewer from "../../../components/basic/JESketchfabViewer";
import queryString from "query-string";
import { Container, Row } from "reactstrap";

/**
 * View per la visualizzazione della pagina di SketchfabViewer
 * Permette di visualizzare un modello SKETCHFAB presente all'indirizzo specificato in
 * props.location.search
 *
 * @property {object} location Oggetto JSON che contiene l'URL della maschera compresi i parametri
 * <pre><div style="line-height:1.3em">
 * {
 *  pathname: String - url comprendente i parametri
 * }
 * </div></pre>
 */

class SketchfabViewer extends Component {
  /**
   * @category viste-standard
   * @subcategory pages
   */
  constructor(props) {
    super(props);
    let values = queryString.parse(this.props.location.search);
    this.state = {
      error: 1,
      idModelloSketchfab: values.pars,
    };
  }

  /**
   * @return {SketchfabViewer}
   */
  render() {
    return (
      <div className='app flex-row align-items-center'>
        <Container>
          <Row className='justify-content-center'>
            {this.state.idModelloSketchfab !== null ||
            this.state.idModelloSketchfab !== undefined ? (
              <UCSketchfabViewer url={this.state.idModelloSketchfab} />
            ) : (
              <span className='clearfix'>
                <h1 className='float-left display-3 mr-4'>
                  MODELLO NON TROVATO
                </h1>
                <h4 className='pt-3'></h4>
                <p className='text-muted float-left'>-</p>
              </span>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}
export default SketchfabViewer;
