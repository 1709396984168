import React, { Component } from "react";
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";

/**
 * Questa vista permetta la visualizzazione della pagina di registrazione per l'accesso
 * @category viste-standard
 * @subcategory pages
 */

class Register extends Component {
  render() {
    return (
      <div className='c-app c-default-layout flex-row align-items-center'>
        <CContainer>
          <CRow className='justify-content-center'>
            <CCol md='12' lg='7' xl='12'>
              <CCard className='mx-12'>
                <CCardBody className='p-4'></CCardBody>
                <CCardFooter className='p-4'></CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
}
export default Register;
