import React, { Component } from "react";
import {
  getUrlApi,
  getDBCONF,
  b64DecodeUnicode,
  logoutHelper,
} from "../../../helpers/Helpers";
import queryString from "query-string";
import Cookies from "universal-cookie";
const cookies = new Cookies();
class Report extends Component {
  /**
   * Questa vista permetta la visualizzazione della pagina di report per la stampa <br>
   *
   *
   * <hr>
   * I dati vengono forniti dal database tramite API: /api/getHtmlTemplate <br>
   *
   * @property {object} location Oggetto JSON che contiene l'URL della maschera compresi i parametri
   * <pre><div style="line-height:1.3em">
   * {
   *  pathname: String - url comprendente i parametri
   * }
   * </div></pre>
   *
   * @category viste-standard
   * @subcategory pages
   */

  constructor(props) {
    super(props);
  }

  /**
   * Metodo per recuperare tramite un API Post i dati della pagina
   * @returns {json}
   */
  getHTMLData = async () => {
    let token = cookies.get("JEProjectJWTTK");
    let uuid = b64DecodeUnicode(cookies.get("JEProjectUUID"));
    const values = queryString.parse(this.props.location.search);
    let parameters = JSON.parse(decodeURI(values.pars));
    let nomeRapportino = values.reportName;
    let printdetails = JSON.parse(decodeURI(values.printdetails));
    let body = {
      parameters: parameters,
      nomeRapportino: nomeRapportino,
      conf: getDBCONF(),
      details: printdetails,
      token: token,
      uuid: uuid,
    };

    let response = await fetch(getUrlApi() + "/api/getHtmlTemplate", {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        if (!response.ok) {
          let jsonResp = response.json();
          jsonResp.then((json) => {
            if (json && json.UUIDLogout) logoutHelper();
          });
          return null;
        } else if (response.ok) {
          return response.blob();
        }
        throw new Error("Network response was not ok.");
      })
      .then(function (myBlob) {
        if (myBlob !== null) {
          const file = new Blob([myBlob], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.location.href = fileURL;
        }
      })
      .catch(function (error) {});
    return response;
  };

  /**
   * @return {Reports}
   */
  render() {
    this.getHTMLData();

    return <div className='app'></div>;
  }
}

export default Report;
